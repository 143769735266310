<template>
	<el-card class="box-card">
		<el-button type="primary" class="d-block mr-0 ml-auto" icon="el-icon-check" @click="save">{{$t('general.save')}}
		</el-button>
		<div class="separator"></div>
		<el-form label-position="top" label-width="100px">
			<el-card shadow="never">
				<div slot="header"><strong>{{$t('account.profile.header')}}</strong></div>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="14" :lg="8" :xl="6">
						<el-form-item :label="$t('account.profile.display-name')">
							<el-input :value="displayName" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="6">
						<el-form-item :label="$t('account.profile.account-age')">
							<el-input :value="accountAgeBeauitfy" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" v-if="gomag.length">
					<el-col :xs="24" :sm="24" :md="14" :lg="8" :xl="6">
						<el-form-item :label="$t('account.profile.gomag-code')">
							<el-input v-model="gomag" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="6">
						<el-form-item :label="$t('account.profile.gomag-url')">
							<el-input v-model="gomag_url" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="12">
						<el-form-item :label="$t('account.profile.company-description')">
							<el-input type="textarea" :rows="4" v-model="description">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="12">
						<el-form-item :label="$t('account.profile.site-url')">
							<el-input v-model="siteUrl">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="12">
						<el-form-item>
							<el-checkbox v-model="disableMissingProducts" :label="$t('account.profile.missing-products')" border></el-checkbox>
						</el-form-item>
					</el-col>
				</el-row>
			</el-card>
			<div class="separator"></div>
			<el-card shadow="never">
				<div slot="header"><strong>{{$t('account.profile.operations-settings')}}</strong></div>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
						<el-form-item :label="$t('account.profile.close-hour')">
							<el-time-select class="fullWidth" v-model="closeHour" :picker-options="timeOptions">
							</el-time-select>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
						<el-form-item :label="$t('account.profile.close-hour-saturday')">
							<el-time-select class="fullWidth" v-model="closeHourSaturday" :picker-options="timeOptions"
								clearable>
							</el-time-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
						<el-form-item :label="$t('account.profile.language')">
							<el-select class="fullWidth" v-model="language" placeholder="Select">
								<template slot="prefix"><i
										:class="['flag', language == 'en' ? 'us' : language]"></i></template>
								<el-option v-for="lang in languages" :key="lang.id" :label="lang.label"
									:value="lang.id">
									<span>
										<i :class="['flag', lang.id == 'en' ? 'us' : lang.id]"></i>
									</span>
									<span>{{lang.label}}</span>
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
						<el-form-item :label="$t('account.profile.profile-default-delivery')">
							<el-input-number class="fullWidth" v-model="defaultDeliveryTime" :max="30">
							</el-input-number>
						</el-form-item>
					</el-col>
				</el-row>
			</el-card>
			<div class="separator"></div>
			<el-card shadow="never">
				<div slot="header"><strong>{{$t('account.profile.holiday')}}</strong></div>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
						<el-form-item :label="$t('sells.orders.title-4')">
							<el-date-picker class="fullWidth" v-model="holidayMinDate" value-format="yyyy-MM-dd"
								type="date" :placeholder="$t('sells.orders.ph-6')">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
						<el-form-item :label="$t('sells.orders.title-5')">
							<el-date-picker class="fullWidth" v-model="holidayMaxDate" value-format="yyyy-MM-dd"
								type="date" :placeholder="$t('sells.orders.ph-7')">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
						<el-form-item label="Status">
							<el-switch v-model="hasHoliday" :active-text="$t('account.profile.holiday')"
								:inactive-text="$t('account.profile.normal-schedule')">
							</el-switch>
						</el-form-item>
					</el-col>
				</el-row>
			</el-card>
		</el-form>
	</el-card>
</template>

<script>
	import i18n from '@/lang';
	import {
		sync,
		call,
		get
	} from 'vuex-pathify';
	import {
		validateUrl
	} from '@/utils/index';
	export default {
		data: () => ({
			languages: [{
				id: 'ro',
				label: i18n.t('general.language-ro')
			}, {
				id: 'en',
				label: i18n.t('general.language-en')
			}],
			timeOptions: {
				start: '07:00',
				step: '00:15',
				end: '23:00'
			}
		}),
		methods: {
			getProfile: call('account/GetProfile'),
			getGoMag: call('account/GetGoMag'),
			saveProfile: call('account/SaveProfile'),
			async save() {
				if (this.siteUrl && !validateUrl(this.siteUrl)) {
					this.sbMsg({
						type: 'error',
						message: this.$t('account.profile.invalid-site-url')
					});
					return false;
				}
				if (this.hasHoliday) {
					if (new Date(this.holidayMinDate) > new Date(this.holidayMaxDate)) {
						this.sbMsg({
							type: 'error',
							message: this.$t('account.profile.holiday-min-date-bigger')
						});
						return false;
					}

					if (new Date(this.holidayMaxDate) < new Date()) {
						this.sbMsg({
							type: 'error',
							message: this.$t('account.profile.holiday-max-date-lower')
						});
						return false;
					}
				}
				try {
					await this.saveProfile();
					this.sbMsg({
						type: 'success',
						message: this.$t('general.changes-saved')
					});
					if (this.interfaceLanguage !== this.language) {
						await this.$store.dispatch('app/ChangeLanguage', this.language);
						window.location.reload();
					}
				} catch (err) {
					this.$reportToSentry(err, {
						extra: {
							fn: 'saveProfile',
							params: {
								profile: this.profile
							}
						}
					});
				}
			}
		},
		computed: {
			...sync('account/informations@profile', {
				displayName: 'displayName',
				accountAge: 'accountAge',
				closeHour: 'closeHour',
				closeHourSaturday: 'closeHourSaturday',
				language: 'language',
				description: 'description',
				defaultDeliveryTime: 'defaultDeliveryTime',
				siteUrl: 'siteUrl',
				holidayMinDate: 'holidayMinDate',
				holidayMaxDate: 'holidayMaxDate',
				hasHoliday: 'hasHoliday',
				disableMissingProducts: 'disableMissingProducts',
			}),
			gomag: sync('account/informations@gomag'),
			profile: get('account/informations@profile'),
			interfaceLanguage: get('app/lang'),
			accountAgeBeauitfy() {
				return this.$moment(new Date(this.accountAge).getTime()).fromNow(true);
			},
			gomag_url() {
				return "https://www.cel.ro/marketplace/" + this.gomag;
			}
		},
		async mounted() {
			try {
				await this.getProfile();
				if(this.gomag.length < 1) this.getGoMag();
			} catch (err) {
				this.$reportToSentry(err, {
					extra: {
						fn: 'getProfile'
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .el-input--prefix .el-input__inner {
		padding-left: 35px;
	}

	::v-deep .el-input__prefix {
		left: 10px;
	}

</style>